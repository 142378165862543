<template>
  <div class="row mx-0 mt-5 justify-content-center">
    <div class="col-12 p-0">
      <SectionTitle :title="title"/>
    </div>
    <div class="col-12 p-0">
      <div class="row align-items-center justify-content-center">
        <div class="col-12 p-0 my-5 text-center">
          <div class="row m-0 justify-content-center">
            <div class="col-7 col-md-5">
              <Search type="text" color="outline" size="big" placeholder="Search" align="left" v-model="projectSearch"/>
            </div>
          </div>
        </div>
        <div class="col-12 p-0 mb-5">
          <div class="row m-0 justify-content-center">
            <div class="col-auto my-3">
              <AppealFilter
                v-model:appealType="appealType"
                :filteredProjects="filteredProjects"
                @updateCountry="updateCountry"
                @updateDonationType="updateDonationType"
                @updatePrice="updatePrice"
                @updateCategory="updateCategory"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 p-0">
      <div class="row m-0">
        <div class="col-12 col-sm-6 my-3" v-for="project in filteredProjects" :key="project.id">
          <AppealCardSimple :project="project" :btn="btnText" :class="{'d-lg-none': showDesktop}" />
          <AppealCard :project="project" class="d-none d-lg-flex" v-if="showDesktop"  />
        </div>
      </div>
      <!-- <div class="row m-0 d-none d-md-flex">
        <div class="col-6 my-3" v-for="project in filteredProjects" :key="project.id">
          <AppealCard :project="project" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Search: defineAsyncComponent(() => import('@/components/Search.vue')),
    SectionTitle: defineAsyncComponent(() => import('@/components/SectionTitle.vue')),
    AppealFilter: defineAsyncComponent(() => import('../components/AppealFilter.vue')),
    AppealCard: defineAsyncComponent(() => import('../components/AppealCard.vue')),
    AppealCardSimple: defineAsyncComponent(() => import('../components/AppealCardSimple.vue'))
  },
  name: 'AppealSection',
  props: ['title', 'showDesktop', 'btnText'],
  data () {
    return {
      projectType: null,
      donationType: null,
      appealType: [],
      country: null,
      priceRange: null,
      projectSearch: '',
      showNumber: 10
    }
  },
  computed: {
    ...mapGetters([
      'projects'
    ]),
    filteredProjects () {
      const search = this.projectSearch.toLowerCase().trim()
      return this.projects.filter(project => {
        // if (project.parent) {
        //   return false
        // }
        if (this.projectType && !this.projectType.includes(project.projectType.id)) {
          return false
        }
        if (this.country && !this.country.includes(project.location.country.id)) {
          return false
        }
        if (this.donationType && this.donationType.length > 0) {
          let ret = false
          if (project && project.projectDonationTypeMaps && project.projectDonationTypeMaps.length > 0) {
            project.projectDonationTypeMaps.forEach((donationType) => {
              if (this.donationType && this.donationType.includes(donationType.donationType.donationTypeID)) {
                ret = true
              }
            })
          }
          if (ret === false) {
            return false
          }
        }
        if (this.appealType && this.appealType.length > 0) {
          let typeRet = false
          this.appealType.forEach(type => {
            if (project[type]) {
              typeRet = true
            }
          })
          if (typeRet === false) {
            return false
          }
        }
        if (this.priceRange) {
          let ret = false
          if (project && project.cost) {
            this.priceRange.forEach((price) => {
              var res = price.split('-')
              for (var i = 0; i < res.length; i++) {
                res[i] = +res[i]
              }
              if (project.cost >= res[0] && project.cost < res[1]) {
                ret = true
              }
            })
          }
          if (ret === false) {
            return false
          }
        }
        if (search && project.name.toLowerCase().indexOf(search) <= -1) {
          return false
        }
        return true
      })
    }
  },
  methods: {
    updateCountry (val) {
      if (val === null) {
        this.country = null
      } else {
        this.country = val
      }
    },
    updateDonationType (val) {
      if (val === null) {
        this.donationType = null
      } else {
        this.donationType = val
      }
    },
    updatePrice (val) {
      if (val === null) {
        this.priceRange = null
      } else {
        this.priceRange = val
      }
    },
    updateCategory (val) {
      if (val === null) {
        this.projectType = null
      } else {
        this.projectType = val
      }
    }
  }
}
</script>
